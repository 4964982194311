.header_users_page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headers_users_page__left_content {
  display: flex;
  gap: 1rem;
  align-items: center;
}

@media screen and (max-width: 639px) {
  .header_users_page {
    flex-direction: column;
    gap: 1rem;
  }
  .header_users_page > * {
    width: 100%;
  }
  .headers_users_page__left_content {
    flex-direction: column;
  }
  .headers_users_page__left_content > * {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 1023px) {
}
